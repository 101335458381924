export default {
  'text':'Texte éditable',
  'textarea':'Bloc de texte éditable',
  'boolean-checkbox':'Case à cocher',
  'boolean-switch':'Switch',
  'web':'URL',
  'separator':'Séparateur',
  '':'',
  'x-kc-group':'Groupe Keycloak',
  'x-gender':'Civilité',
  'x-email-primary':'Adresse email',
  'x-email-secondary':'Adresse email secondaire',
  'x-mobile-primary':'Téléphone mobile',
  'x-mobile-secondary':'Téléphone mobile secondaire',
  'x-siret':'SIRET',
  'x-pgp':'Clé PGP publique'
}

