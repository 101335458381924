import { BaseElement, html, css, getFormControls } from 'Elements';
import { Fetcher } from 'Utils';


class Popup extends BaseElement {
  static get styles() {
    return [
      css`
      form {
        width:600px;
        max-width:90vw;
        display:flex;
        flex-direction:column;
        align-items: center;
        gap: 10px;
        justify-content: flex-start;
      }

      .line {
        display:flex;
        flex-direction:row;
        align-items: center;
        gap: 10px;
        width:100%;
        justify-content: flex-start
      }

      sl-input {
        width:100%;
      }

      label {
        min-width:150px;
      }

      .buttons {
        display:flex;
        justify-content:space-between;
        margin-top:40px;
      }

      sl-select {
        width:100%;
      }

      sl-select::part(display-input) {
        font-family: Calibri;
        font-size:14px;
      }

      sl-option::part(base) {
        font-family: Calibri;
        font-size:14px;
      }

      sl-popup::part(display-input) {
        font-family: Calibri;
        font-size:14px;
      }
      `
    ];
  }

  static get properties() {
    return {
      name: { type: String }, // used chrome autocomplete memory
      apipath: { type: String },
      minlength: { type: Number },
      maxlength: { type: Number },
      item: { type: Object }
    }
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            cancel:'Cancel',
            save:'Save'
          },
        },
        french:{
          translation: {
            cancel:'Annuler',
            save:'Enregistrer'
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.name = '';
    this.item = null;
    this.apipath = '';
    this.minlength = 0;
    this.maxlength = 255;
  }

  firstUpdated() {
    this.modal = this.shadowRoot.querySelector('modal-dialog');
    this.input = this.shadowRoot.querySelector('sl-input');
    this.form = this.shadowRoot.querySelector('form');
    this.btSave = this.shadowRoot.querySelector('sl-button#btsave');
  }

  show(item) {
    
    this.formReset();

    if (!item) {
      this.modal.label = 'Ajouter une entité';
      this.input.value = '';
    } else {
      this.modal.label = 'Modifier une entité';
      this.input.value = item.name;
    }
    this.modal.show();
    this.item = item;
    this.requestUpdate();
    setTimeout(() => { this.focusField() }, 200);
  }

  focusField() {
    this.input.focus();
    // go to the end of the input
    this.input.setSelectionRange(this.input.value.length, this.input.value.length);
  }

  hide() {
    this.modal.hide();
  }

  onInput(e) {
    e.preventDefault();
    e.stopPropagation();   

    if (this.minlength && this.input.value.length < this.minlength) {
      this.btSave.disabled = true;
      return;
    }

    if (this.maxlength && this.input.value.length > this.maxlength) {
      this.btSave.disabled = true;
      return;
    }

    this.btSave.disabled = false;
  }

  formReset() {
    const elements = getFormControls(this.form);
    elements.forEach(el => {
      el.value = '';
      el.setCustomValidity('');
    });
  }

  async handleSubmit(ev) {
    ev.preventDefault();
    ev.stopPropagation();

    this.btSave.loading = true;
    this.btSave.disabled = true;

    const url = this.item ? `${this.apipath}/${this.item._id}/update` : `${this.apipath}/add`;

    const response = await Fetcher.post(url, { name: this.input.value });
    this.btSave.loading = false;
    this.btSave.disabled = false;

    if (response.ok) {
      this.form.reset();
      this.modal.hide();
      if (response.data?.insertedId) {
        this.dispatchEvent(new CustomEvent('updated', { detail: { insertedId: response.data.insertedId } }));
      } else {
        this.dispatchEvent(new CustomEvent('updated', { detail: response.data }));
      }
    } else {
      if (response.error === 'EVALIDATION_FAILED') {
        this.input.setCustomValidity('Valeur obligatoire et longueur minimum de 3 caractères');
        this.input.reportValidity();
      }
    }

  }
  
  render() {
    return html`
      <modal-dialog>
        <style type="text/css">${this.constructor.styles}</style>
        <form @submit="${this.handleSubmit}">
          <div class="line">
            <label>Nom</label>
            <sl-input 
              size="small" 
              type="text" 
              no-spin-buttons 
              required 
              minlength="${this.minlength}" 
              maxlength="${this.maxlength}"
              name="${this.name}_fr" 
              value="${this.item ? this.item.name : ''}"
              @sl-input="${this.onInput}">
              ${this.item ? this.item.name : ''}
            </sl-input>
          </div>
        </form>
        <sl-button slot="bt1" close="true" variant="text">${this._tl('cancel')}</sl-button>
        <sl-button slot="bt2" @click="${this.handleSubmit}" id="btsave" variant="primary" disabled>${this._tl('save')}</sl-button>
      </modal-dialog>
    `;
  }

}

customElements.define('entity-edit', Popup);