/* eslint-disable indent */
import { PageElement, html, css } from 'Elements';
import { Fetcher, UrlParams } from 'Utils';

import ATTRIBUTES from './attributes.js';
import './entity-edit.js';
import './entity-del.js';
import './entity-attribute-edit.js';
import './entity-attribute-del.js';
import './entity-tab-edit.js';
import './entity-tab-del.js';

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        :host {
          font-family: Calibri;
          font-size:0.9em;
        }
        sl-progress-bar {
          width:200px;
          margin-left:auto;
          margin-right:auto;
        }

        .container {
          display:flex;
          width:100%;
        }

        sl-tree {
          overflow-y: auto;
          height:100%;
          --indent-guide-width: 1px;
        }

        /*
        sl-tree-item::part(expand-button) {
          width:2px;
          padding-right:0px;
          padding-left:4px;
          margin-left:0px;
        }
        */

        sl-split-panel {
          min-height:80vh;
        }

        sl-tree-item::part(label) {
          font-family:Calibri;
          color:var(--text-color);
          width:100%;
        }

        .menu_item {
          display: flex;
          width:100%;
          white-space: nowrap;
          align-items: center;
          justify-content: flex-end;
        }

        h3 sl-button {
          height:30px;
        }

        h3 sl-button::part(label) {
          padding:0px;
          margin:0px;
          margin-left:10px;
        }

        m-icon {
          cursor:pointer;
        }

        m-icon[name="search"] {
          color:lightgray;
        }

        m-icon[name="more_vert"] {
          margin-right:5px;
          font-size:24px;
          opacity:0.5;
        }

        m-icon[name="more_vert"]:hover {
          opacity:1;
        }

        sl-button::part(spinner) {
          font-size:18px;
          margin-top:-5px;
          margin-left:5px;
        }

        m-icon[name="add"] {
          font-size:30px;
          margin-top:-4px;
        }

        m-icon[name="menu"] {
          font-size:30px;
          margin-right:5px;
        }

        .start {
          display: flex;
          flex-direction: column;
          overflow: hidden;
          padding:5px;
        }

        .end {
          display: flex;
          padding-left:10px;
          overflow: hidden;
        }

        sl-dropdown {
          padding-top: 6px;
          position: absolute;
        }

        sl-menu-item::part(label) {
          font-size:0.8em;
        }

        sl-menu-item::part(checked-icon) {
          width:5px;
        }

        sl-menu-item::part(submenu-icon) {
          width:10px;
        }

        sl-menu-item::part(prefix) {
          opacity:0.5;
          width:30px;
          display:flex;
          justify-content:center;
          align-items:center;
        }
        
        #group_name_del {
          font-weight:bold;
        }

        .search {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .search sl-input {
          width:100%;
        }

        .search sl-button {
          width:40px;
          height:30px;
        }

        .object {
          width:100%;
        }

        .attributes_title {
          display:grid;
          margin-bottom:0px;
          height:20px;
        }

        .attribute {
          display: grid;
          padding:3px;
          line-height:25px;
        }

        .attribute:hover {
          background-color: var(--sl-color-neutral-100);
        }

        .attributes_title.col4 {
          grid-template-columns: 1fr 1fr 1fr 20px;
        }

        .attribute.col4 {
          grid-template-columns: 1fr 1fr 1fr 20px;
        }

        .attributes_title.col3 {
          grid-template-columns: 1fr 1fr 20px;
        }

        .attribute.col3 {
          grid-template-columns: 1fr 1fr 20px;
        }

        .attribute sl-dropdown {
          margin-top:-5px;
        }

      `
    ];
  }

  static get properties() {
    return {
      items: { type: Array },
      item: { type: Object },
      selected: { type: String },
      q: { type: String },
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            refresh:'Refresh',
            add:'Add'
          },
        },
        french:{
          translation: {
            refresh:'Rafraîchir',
            add:'Ajouter'
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this._loggingEnabled = true;
    this.q = UrlParams.get('q') || null;
    this.selected = UrlParams.get('gid') || null;
    this.tab = UrlParams.get('tab') || null;
    this.tabDefaultName = 'Propriétés';

    this.loading = true;
    this.items = [];
    this.item = null;
  }

  async firstUpdated() {
    super.firstUpdated();
    this.modalEntityEdit = this.shadowRoot.querySelector('entity-edit');
    this.modalEntityDel = this.shadowRoot.querySelector('entity-del');
    this.modalEntityAttributEdit = this.shadowRoot.querySelector('entity-attribute-edit');
    this.modalEntityAttributDel = this.shadowRoot.querySelector('entity-attribute-del');
    this.modalEntityTabEdit = this.shadowRoot.querySelector('entity-tab-edit');
    this.modalEntityTabDel = this.shadowRoot.querySelector('entity-tab-del');
    await this.refreshData();
    if (this.selected) this.setSelected(this.selected);
  }

  async refreshData(ev) {

    let target;
    if (ev) {
      target = ev.target.tagName === 'M-ICON' ? target = ev.target.parentElement : target = ev.target;
      target.loading = true;
    }

    this.loading = true;
    if (target) target.loading = true;

    let url = 'private/admin/entities/list';
    if (this.q) {
      url+='?q='+this.q;
      UrlParams.set('q', this.q);
    } else {
      UrlParams.del('q');
    }
    const response = await Fetcher.get(url);
    if (response && response.data) {
      this.items = response.data;
    }
    this.loading = false;
    if (target) target.loading = false;
  }

  getSelectedItem() {
    this.item = this.items.filter(item => item._id === this.selected)[0];
    return this.item;
  }

  resetSelected() {
    const selectedEl = this.shadowRoot.querySelector('sl-tree-item[selected]');
    if (selectedEl) selectedEl.removeAttribute('selected');
    UrlParams.del('gid');
    this.selected = null;
    this.item = null;
  }

  setSelected(id, scroll) {
    const el = this.shadowRoot.querySelector(`sl-tree-item[data-id="${id}"]`);
    if (el) {
      el.selected = true;
      // scroll the window to show the element
      if (scroll) el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      UrlParams.set('gid', id);
      this.selected = id;
      this.item = this.getSelectedItem();
    } else {
      console.error(`Element not found ${id}`);
    }
  }

  addItem() {
    this.modalEntityEdit.show();
  }

  updateItem() {
    this.modalEntityEdit.show(this.getSelectedItem());
  }

  updateTab(ev) {
    const lineNumber = ev.target.getAttribute('data-line-number');
    this.modalEntityTabEdit.show(this.getSelectedItem(), lineNumber);
  }

  deleteItemConfirm(ev) {
    if (ev.target.disabled) return;
    this.modalEntityDel.show(this.getSelectedItem());
  }

  deleteTabConfirm(ev) {
    if (ev.target.disabled) return;
    const lineNumber = ev.target.getAttribute('data-line-number');
    this.modalEntityTabDel.show(this.getSelectedItem(), lineNumber);
  }

  deleteAttributeConfirm(ev) {
    if (ev.target.disabled) return;
    const lineNumber = ev.target.getAttribute('data-line-number');
    this.modalEntityAttributDel.show(this.getSelectedItem(), lineNumber);
  }

  handleTreeItemClick(event) {
    const selectedEl = event.detail.selection[0];
    const tab = selectedEl.getAttribute('data-tab');
    if (tab) {
      UrlParams.set('tab', tab);
      this.tab = tab;
    } else {
      UrlParams.del('tab');
      this.tab = null;
    }
    this.setSelected(selectedEl.getAttribute('data-id'));
    this.requestUpdate();
  }

  async handleSearchInput() {
    this.q = this.shadowRoot.querySelector('sl-input').value;
    await this.refreshData();
  }

  async onEntityDeleted(ev) {
    // found the item to delete
    const found = this.items.filter(item => item._id === ev.detail.item._id)[0];
    if (found) {
      const index = this.items.indexOf(found);
      this.items.splice(index, 1);
    }
    this.resetSelected();
  }

  async onEntityUpdated(ev) {
    if (ev?.detail?.insertedId) {
      // added
      await this.refreshData();
      this.resetSelected();
      this.setSelected(ev.detail.insertedId, true);
    } else {
      // updated
      this.updateEntity(ev.detail);
    }
    // needed to refresh the ui
    await this.requestUpdate();
  }

  updateEntity(properties) {
    const found = this.items.filter(item => item._id === properties._id)[0];
    if (found) {
      // merge properties
      Object.assign(found, properties);
    }
  }

  async onAttributUpdated(ev) {
    this.updateEntity(ev.detail.parent);
    await this.requestUpdate();    
  }

  async onAttributDeleted(ev) {
    this.updateEntity(ev.detail);
    await this.requestUpdate();
  }

  async onTabUpdated(ev) {
    this.updateEntity(ev.detail.parent);
    await this.requestUpdate();    
  }

  async onTabDeleted(ev) {
    this.updateEntity(ev.detail);
    await this.requestUpdate();
  }

  async addAttribute() {
    this.modalEntityAttributEdit.show(this.getSelectedItem());
  }

  async updateAttribute(ev) {
    const attributeNumber = ev.target.getAttribute('data-line-number');
    this.modalEntityAttributEdit.show(this.getSelectedItem(), attributeNumber);
  }

  getAttributeType(type) {
    return ATTRIBUTES[type] || type;
  }

  async moveThing(ev, thingAttr, upOrDown) {
    const lineNumber = ev.target.getAttribute('data-line-number');
    const item = this.getSelectedItem();
    const thing = item[thingAttr][lineNumber];   
    if (upOrDown === 'down') {
      item[thingAttr].splice(lineNumber, 1);
      item[thingAttr].splice(parseInt(lineNumber)+1, 0, thing);
    } else {
      item[thingAttr].splice(lineNumber, 1);
      item[thingAttr].splice(parseInt(lineNumber)-1, 0, thing);
    }
    this.updateEntity(item);
    const url = `private/admin/entities/${this.item._id}/update`;
    await Fetcher.post(url, item);
    this.requestUpdate();
  }

  async moveAttributeDown(ev) {
    this.moveThing(ev, 'attributes', 'down');
  }

  async moveAttributeUp(ev) {
    this.moveThing(ev, 'attributes', 'up');
  }

  async moveTabDown(ev) {
    this.moveThing(ev, 'tabs', 'down');
  }

  async moveTabUp(ev) {
    this.moveThing(ev, 'tabs', 'up');
  }

  addTab() {
    this.modalEntityTabEdit.show(this.getSelectedItem());
  }

  /*
  handleContextMenu(ev, count) {
    const line = ev.target.closest('.attribute');
    //console.log(ev);
    const x = ev.clientX/2;
    const y = ev.clientY/2;
    const dropdown = line.querySelector('sl-dropdown');
    // sl-after-show'
    if (dropdown) {
      dropdown.show();
      setTimeout(() => {
        const popup = dropdown.shadowRoot.querySelector('sl-popup');
        const menu = popup.shadowRoot.querySelector('div');
        //console.log(menu.style.position);
        //console.log(menu.style.left);
        //console.log(menu.style.top);
        //console.log(menu);
        if (menu) {
          menu.style.position = 'fixed';
          menu.style.left = `${x}px`;
          menu.style.top = `${y}px`;
          //console.log('pos', menu.style.position);
          //dropdown.reposition(0,0);
        }

      }, 300) 
      ev.preventDefault();
    }
  }
  */

  render() {
    
    const gridCss = this.tab ? 'col3' : 'col4';
    const iconAdd = html`<m-icon slot="prefix" name="add" nogradient></m-icon>`;
    const iconModify = html`<m-icon slot="prefix" name="edit" nogradient></m-icon>`;
    const iconDelete = html`<m-icon slot="prefix" name="delete_forever" nogradient></m-icon>`;
    const iconMoveUp = html`<m-icon slot="prefix" name="keyboard_arrow_up" nogradient></m-icon>`;
    const iconMoveDown = html`<m-icon slot="prefix" name="keyboard_arrow_down" nogradient></m-icon>`;

    return html`
      <div class="content_page">
        <div class="item">
          <h3>
            <m-icon name="menu"></m-icon>
            <span>Entités &amp; attributs</span>
            <sl-button variant="text" size="small" title="${this._tl('refresh')}" @click="${this.refresh}"><m-icon name="refresh"></sl-button>
          </h3>
          <hr-custom></hr-custom>
          <br/>
          ${this.loading 
            ? html`<sl-progress-bar indeterminate></sl-progress-bar>` 
            : html`<sl-split-panel position-in-pixels="300">
                  <div slot="start" class="start">
                    <div class="search">
                      <sl-input size="small" name="eq" @sl-change="${this.handleSearchInput}" clearable .value="${this.q || ''}">
                        <m-icon nogradient name="search" slot="suffix"></m-icon>
                      </sl-input>
                      <sl-button variant="text" size="small" title="${this._tl('add')}" @click="${this.addItem}"><m-icon name="add"></sl-button>
                    </div>
                    <br/>
                    ${this.items.length 
                      ? html`<sl-tree @sl-selection-change="${this.handleTreeItemClick}">
                        ${this.items.map(item => html`
                        <sl-tree-item data-id="${item._id}" title="${item._id}" expanded>
                          <div class="menu_item">
                            <span>${item.name}</span>
                            <sl-dropdown>
                              <m-icon slot="trigger" name="more_vert"></m-icon>
                              <sl-menu>
                                <sl-menu-item @click="${this.updateItem}">Modifier${iconModify}</sl-menu-item>
                                <sl-menu-item @click="${this.addTab}">Nouvel onglet${iconAdd}</sl-menu-item>
                                <sl-divider></sl-divider>
                                <sl-menu-item @click="${this.deleteItemConfirm}">Supprimer${iconDelete}</sl-menu-item>
                              </sl-menu>
                            </sl-dropdown>
                          </div>
                          ${item.tabs 
                            ? html`${item.tabs.map((tab, count) => html`
                                  <sl-tree-item data-id="${item._id}" data-tab="${tab.id}">
                                    <div class="menu_item">
                                      <span>${tab.title}</span>
                                      <sl-dropdown>
                                        <m-icon slot="trigger" name="more_vert"></m-icon>
                                        <sl-menu>
                                          <sl-menu-item data-line-number="${count}" @click="${this.updateTab}">Modifier${iconModify}</sl-menu-item>
                                          <sl-menu-item data-line-number="${count}" @click="${this.deleteTabConfirm}" ?disabled="${tab.id==='default'}">Supprimer${iconDelete}</sl-menu-item>
                                          <sl-divider></sl-divider>
                                          <sl-menu-item data-line-number="${count}" @click="${this.moveTabUp}" ?disabled="${count === 0}">Monter${iconMoveUp}</m-icon></sl-menu-item>
                                          <sl-menu-item data-line-number="${count}" @click="${this.moveTabDown}" ?disabled="${count === item.tabs.length-1}">Descendre${iconMoveDown}</sl-menu-item>
                                        </sl-menu>
                                      </sl-dropdown>
                                    </div>
                                  </sl-tree-item>`
                                )}`
                            : ''
                            }
                        </sl-tree-item>
                      `)}
                    </sl-tree>
                    ` : html`<div>Aucune entrée</div>`}
                  </div>
                  <div slot="end" class="end">
                    <!-- attributes -->
                    ${this.item 
                      ? html`<div class="object">
                          <h2>${this.item.name}</h2>
                          <br/>
                          <div class="attributes_title ${gridCss}">
                            <span>Attribut</span>
                            <span>Type</span>
                            ${!this.tab ? html`<span>Onglet</span>` : ''}
                            <sl-button variant="text" size="small" title="${this._tl('add')}" @click="${this.addAttribute}"><m-icon name="add"></sl-button>
                          </div>
                          <hr/>
                          <div>
                            ${this.item.attributes 
                              ? this.item.attributes.map((attribute, count) => {
                                if (!this.tab || (attribute && this.tab === attribute.tab)) {
                                  return html`
                                  <div class="attribute ${gridCss}" @contextmenu="${this.handleContextMenu}">
                                    <div>${attribute.name}</div>
                                    <div>${this.getAttributeType(attribute.type)}</div>
                                    ${!this.tab ? html`<div>${this.item.tabs.find(tab => tab.id === attribute.tab)?.title || 'N/A'}</div>` : ''}
                                    <div>
                                      <sl-dropdown>
                                        <m-icon slot="trigger" name="more_vert"></m-icon>
                                        <sl-menu>
                                          <sl-menu-item data-line-number="${count}" @click="${this.updateAttribute}">Modifier${iconModify}</sl-menu-item>
                                          <sl-menu-item data-line-number="${count}" @click="${this.deleteAttributeConfirm}">Supprimer${iconDelete}</sl-menu-item>
                                          <sl-divider></sl-divider>
                                          <sl-menu-item data-line-number="${count}" @click="${this.moveAttributeUp}" ?disabled="${count === 0}">Monter${iconMoveUp}</sl-menu-item>
                                          <sl-menu-item data-line-number="${count}" @click="${this.moveAttributeDown}" ?disabled="${count === this.item.attributes.length-1}">Descendre${iconMoveDown}</sl-menu-item>
                                        </sl-menu>
                                      </sl-dropdown>
                                    </div>
                                  </div>`}
                                  })
                                
                              : 'Aucun attributs'
                            }
                          </div>
                        </div>` 
                      : html`<div></div>`
                    }
                  </div>
                </div>
                </sl-split-panel>
                `
          }
        </div>
      </div>
      <entity-edit apipath="private/admin/entities" name="entity" minlength="3" maxlength="255" @updated="${this.onEntityUpdated}"></entity-edit>
      <entity-del apipath="private/admin/entities" @deleted="${this.onEntityDeleted}"></entity-del>
      <entity-attribute-edit apipath="private/admin/entities" name="attribute" minlength="3" maxlength="255" @updated="${this.onAttributUpdated}"></entity-attribute-edit>
      <entity-attribute-del apipath="private/admin/entities" @deleted="${this.onAttributDeleted}"></entity-attribute-del>
      <entity-tab-edit apipath="private/admin/entities" name="tab" minlength="3" maxlength="255" @updated="${this.onTabUpdated}"></entity-tab-edit>
      <entity-tab-del apipath="private/admin/entities" @deleted="${this.onTabDeleted}"></entity-tab-del>
    `;
  }

}

customElements.define('page-entities', Page);